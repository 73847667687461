

// @ts-nocheck

import Vue from 'vue';

import { mapActions, mapState } from 'vuex';
import { generalActions } from '@/store/modules/general/names';

/*
    Для работы hot-reload и правок чата требуется использовать локальный путь до компоненты чата
    import ChatComponent from '../../../frontend-chat/src/NacometaChat.vue';
*/

import ChatComponent from '@git.webpp.ru/frontend-chat';
import currentLanguage from '@/tools/currentLanguage';
import axiosInstance from '@/api/axios-instance';

import rest from '@/api/index';

import { ComposedState } from '@/types';

interface ILangForTable {
    lang: string;
    download: boolean;
    upload: boolean;
    error: string;
}

interface INewFileConfig {
    lang: string;
    file: FormData;
}

export default Vue.extend({
    name: 'LangManager',

    components: { ChatComponent },

    data() {
        return {
            socket: null as null | WebSocket,
            socketOptions: null as any,
            headers: [
                {
                    text: 'Languagesss',
                    value: 'lang',
                    sortable: false,
                },
                {
                    text: 'Get the latest version',
                    value: 'download',
                    sortable: false,
                },
                {
                    text: 'Upload the updated version of the language file',
                    value: 'upload',
                    sortable: false,
                },
                {
                    text: 'Server response',
                    value: 'error',
                    sortable: false,
                },
            ],
            langList: null as String[] | null,
            loading: false,
            newFileConfig: null as null | INewFileConfig,
            errorMessage: null,
            successMessage: null, 
            formData: null as FormData | null,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            socketIsOpen: (state: ComposedState) => state.websocket.socketIsOpen,
            currentUser: (state: ComposedState) => state.admin.admin,
            token: (state: ComposedState) => state.admin.access,
        }),

        axiosInstance() {
            return axiosInstance;
        },

        templatesList(): IChatTemplateItem[] {
            return [
                {
                    name: 'templateListItem1',
                    value: this.translations?.chatPage?.templateListItem1,
                },
            ];
        },

        vocabulary() {
            return {
                language: currentLanguage(this.langList),
                sidebar: {
                    searchPlaceholder: 'Поиск',
                    writingLabel: 'Печатает...',
                    noDialogsLabel: 'У вас пока нет сообщений',
                    lastMessageIsFile: 'Файл',
                },
                dialogs: {
                    noDialogHeading: 'Сообщения будут появляться здесь',
                    noDialogDescription:
                        'Как только вы напишете другому мечтателю или кто-то напишет вам.',
                    dialogNoMessagesTitle: `Используя мессенджер UFANDAO, вы можете общаться 
                        с другими участниками, поддерживать мечты и отправлять донаты.`,
                    dialogNoMessagesText: `Напишите сообщение или просто воспользуйтесь шаблоном, 
                        который автоматически переводится на язык получателя.`,
                    dialogMessageYou: 'Вы',
                    dialogTimeToday: 'Сегодня',
                    dialogTimeYesterday: 'Вчера',
                    dialogInputPlaceholder: 'Написать сообщение',
                    dialogAddFileButton: 'Прикрепить файл (Ctrl+O)',
                    dialogSendMessageButton: 'Отправить (Ctrl + Enter)',
                    fileUploadFailed: 'Не удалось загрузить файл',
                    fileInvalidSize: 'Максимальный размер файла 25 МБ.',
                    fileInvalidFormat: 'Неверный тип файла',
                    fileUploadSuccess: 'Файл загружен и готов к отправке',
                    fileSaveToCloudFailed: 'Не удалось отправить файл',
                    fileDropErrorNoDialog: 'Пожалуйста, выберите квитанцию',
                    fileDropzoneText: 'Загрузить файл',
                },
                templates: {
                    templatesHeading: 'Быстрые сообщения',
                    templatesHint: 'Сообщения будут автоматически переведены на язык получателя.',
                },
            };
        },
        chatConfig() {
            return {
                theme: 'admin',
                urls: {
                    noOpenedDialogsImage: 
                        `${process.env.VUE_APP_BASE_FRONT_URL}/img/chat/ufandao-no-messages.png`,
                    dialogsMessageFileIcon: 
                        `${process.env.VUE_APP_BASE_FRONT_URL}/img/chat/message-file.svg`,
                },
            };
        },
    },

    watch: {
        socketIsOpen(newVal) {
            this.socket = newVal ? this.$socket : null;
            this.socketOptions = newVal ? this.$options.sockets : null;
        },
    },

    async mounted() {
        await this.getCountries();

        this.socket = this.$socket;
        this.socketOptions = this.$options.sockets;
    },

    methods: {
        ...mapActions({
            getCountries: generalActions.getCountries,
        }),
    },
});
